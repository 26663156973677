import React , { useState } from 'react'
import { APP_NAME, AVATAR_ROOT, COVER_ROOT } from '../../constants/general'
import { Header, HeaderBox , Footer, HeaderBoutique } from '../../components/imports'
import { Aside } from '../../components/Aside'
import User from '../../class/User'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import Func from '../../class/Func'
import { useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import wilaya from '../../Json/wilaya.json'
import { useTranslation } from 'react-i18next'

function EditBoutique() {

  const {t} = useTranslation()

  const [get_params] = useSearchParams()

  const navigate = useNavigate()

  const context = useContext(Context)

  const [infos, setInfos] = useState(false)

  const [selected, setSelected] = useState(0)

  const [flash, setFlash] = useState('')

  const [loading, setLoading] = useState(false) 

  const [delaislivraison, setDelaislivraison] = useState(1)

  const [livraison_moi_meme, setLivraison_moi_meme] = useState(1) 

  const [livraison, setLivraison] = useState() 

  const uploadAvatar = async (e) => {

    let file = e.target.files[0]
  
    if(file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
  
    setLoading(true)
  
    let results = await User.photoUpload(file)
  
    setLoading(false)
  
    if (results.success && results.avatar !== undefined){
  
        localStorage.setItem('avatar', results.avatar)
  
        document.querySelectorAll('.avatar_img').forEach(elem => {
  
            elem.src = AVATAR_ROOT + results.avatar + '.jpg?t=' + Date.now()
  
        })
  
        setFlash('')
  
    }else{
  
        setFlash({type: 'error', message: results.message})
        
    }
  
  }

  const uploadCover = async (e) => {

    let file = e.target.files[0]
  
    if(file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
  
    setLoading(true)
  
    let results = await User.coverUpload(file)
  
    setLoading(false)
  
    if (results.success && results.cover !== undefined){
  
        localStorage.setItem('cover', results.cover)
  
        document.querySelectorAll('.cover_img').forEach(elem => {
  
            elem.src = COVER_ROOT + results.cover + '.jpg?t=' + Date.now()
  
        })
  
        setFlash('')
  
    }else{
  
        setFlash({type: 'error', message: results.message})
        
    }
  
  }
  
  const myInfos = async () => {

    let results = await User.session(2)

    if(results.success){

      setInfos(results.data)

      setLivraison(results.data.livraison)

      setSelected(results.data.politique)

    }

  }

  const modifier = async (e) =>{

    e.preventDefault()

    setLoading(true)

    let inputs = e.target

    const results = await User.modifier_Boutique(inputs)

    setLoading(false)

    if(results.success){

        setFlash({type: 'succes', message: t('t239')})

    }else{

        setFlash({type: 'error', message: results.message})

    }

    window.scrollTo(0, 0)

  }

  const setSelectedVar = (v) =>{

    setSelected(v)
    
    console.log(v)
  }

  const copier = (username) => {

    navigator.clipboard.writeText('https://www.taqalid.dz/boutique/' + username)

    Func.alert("URL copiée")

  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()

    if(get_params.get("inscription") !== null) setFlash({type: "succes", message: "La boutique a été configurée avec succès, veuillez compléter les informations de votre boutique."})

    document.title = t('t211') + " - " + APP_NAME
    
    window.scrollTo(0, 0)

  }, [])

  return (

    <div>
      <Header/>

      <HeaderBox/>
      
      <HeaderBoutique/>

       <div className='c mb20'>

          <div className='flex'>

            <Aside/>

            <div className='col-75 mt80 p20m m_mt_0'>

              <h1 className='cl8 ff mb30'>{t('t211')}</h1>

              {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

              {
                infos

                ?

                <form method="POST" className="form mb30" onSubmit={(e) => modifier(e)}>

                <label className='M fw5 fz13 mb10'>{t('t212')}</label>
                <input type="text" name="name" placeholder={t('t212')} required={true} className='bg7' defaultValue={infos.name} />

                <label className='M fw5 fz13 mb10'>Nom d'utilisateur</label>
                <input type="text" name="username" placeholder={t('t212')} disabled={true} className='bg7' value={'https://www.taqalid.dz/boutique/' + infos.username} style={{marginBottom: '20px'}}/>
                
                <span className='btn bg3 cl1' onClick={() => copier(infos.username)}>Copier</span>

                <label className='mt40 mb10'>{t('t213')}</label>
                <input type="email" name="email" placeholder={t('t213')} className='bg7' value={infos.email} disabled={true}/>

                <label className='mt10 mb10'>{t('t214')}</label>
                <input type="tel" name="tele" placeholder={t('t214')} required="true"className='bg7' defaultValue={infos.tele} /> 
            
                <label className='M fw5 mb10 fz13'>{t('t215')}</label>
                <textarea type="text" name="description" rows="6" placeholder="..." required="true"className='bg7' defaultValue={infos.description}/> 

                <div class="mb20">

                  <label className='M fw5 fz13 mb10'>{t('t216')}</label>
                  
                  <img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar_img"/>

                  {

                    loading

                    ?
                    
                    <div className="lds-dual-ring col"></div>

                    :

                    <div className='mt20'>

                      <input type="file" name="picBoutique" id="file-6" class="inputfile" onChange={e => uploadAvatar(e)} style={{ display: 'none'}}/>
                     
                      <label for="file-6"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz11'>{t('t217')}</span></label>
                    </div>

                  }
                
               
                </div>

                <div class="mb20">
                  
                  <label className='M fw5 fz13 mb10'>{t('t218')}</label>

                  <img src={Func.cover(localStorage.getItem('cover')) } className="cover_img"/>

                  {
                    
                    loading

                  ?
                    
                    <div className="lds-dual-ring col"></div>

                  :

                    <div className='mt20'>

                      <input type="file" name="cover" id="file-5" className="inputfile" onChange={e => uploadCover(e)} style={{ display: 'none'}}/>
                      
                      <label for="file-5" className='mr30'><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz11'>{t('t219')}</span></label>
                    
                    </div>

                  }
                  
               
                </div>
               
                
                <label>{t('t220')}</label>

                <select name="wilaya" required="true" className='bg7' defaultValue={infos.wilaya}>

                 { wilaya.map((getwilaya, index) => (<option value={getwilaya.id} key={index}>{getwilaya.name}</option>))}

                </select>

                <label className='M fw5 fz13 mb10'>{t('t221')}</label>
                
                <select  name="livraison" required="true" className='bg7' defaultValue={infos.livraison} onChange={e => setLivraison(e.target.value)}>

                  <option>Yalidine</option>
                  <option>EMS</option>
                  <option>ZR Express</option>
                  <option>Zimou Express</option>
                  <option>E-com Delivery</option>
                  <option>Fast Horse Express</option>
                  <option>NOEST Express</option>
                  <option value={0}>{t('t222')}</option>
                 
                </select>

                { livraison == "0" && 

                  <>
                    <p className='mb20 fz09'>{t('t223')}</p>
                    <input type="text" name="autre_partenaire" placeholder={t('t221')} className='bg7' defaultValue={infos.autre_partenaire} onChange={e => setInfos(e.target.value)} required={true}/>  
                  </>

                }

                {/* { livraison == "1"
                
                && 

                <> */}
                
                  <p className='M fw5 fz13 mb10'>{t('t224')}</p>

                  <select name="livraison_moi_meme" required="true" className='bg7' onChange={e => setLivraison_moi_meme(e.target.value)} defaultValue={infos.livraison_moi_meme}>
                    <option value={1}>{t('t225')}</option>
                    <option value={2}>{t('t226')}</option>
                  </select>  
                {/* </> */}

                {/* // } */}
        

                <div className='M fw5 fz13 mb50 mt20'><Link to={"/tarifs"}  className='cl1 btn bg2 mb20'>{t('t195')}</Link></div>
        
                <label className='M fw5 fz13 mb10'>{t('t200')}</label>
      
                <select name="delaislivraison" required="true" className='bg7' onChange={e => setDelaislivraison(e.target.value)} defaultValue={infos.delaislivraison}>
                  <option value='jour.meme'>{t('jour.meme')}</option>
                  <option value='24heures'>{t('24heures')}</option>
                  <option value='t227'>{t('t227')}</option>
                  <option value='t228'>{t('t228')}</option>
                  <option value='t229'>{t('t229')}</option>
                  <option value='t230'>{t('t230')}</option>
                  <option value='t231'>{t('t231')}</option>
                </select>

                <label>{t('t26')}</label>

                <select name="politique" id='politique' required="true" className='bg7' onChange={e => setSelectedVar(e.target.value)} defaultValue={infos.politique}>
                  <option value="0">{t('t232')}</option>
                  <option value="1">{t('t233')}</option>
                </select>

                {

                  selected == "1"

                ?

                  <textarea rows="8" name="politiquetext" required="true" className='bg7' defaultValue={infos.politiquetext}/> 

                :

                  <textarea rows="16" placeholder={`• ${t('t204')}
• ${t('t205')}
• ${t('t206')}
• ${t('t207')}
• ${t('t207_2')}`} className='bg7' disabled= {true}/> 

                }

                  <h2 className='mb20'>{t('t234')}</h2>

                  {

                    infos.closed == null || infos.closed == 0

                    ?
                    
                    <p className='mb70'>{t('t235')} . <Link to={'/suspendre-boutique'} className="cl3">{t('t237')}</Link></p>

                    :
                    
                    <p className='mb70'>{t('t236')} . <Link to={'/suspendre-boutique?closed=true'} className="cl3">{t('t238')}</Link></p>

                  }
              
                  <input type="submit" name="save" value={t('enr')} className="btn bg3 cl1 hover-bg2 hover-cl1 col-3"/>
                              
                </form>

                :

                <div className='flex jc-center mt40 mb40'>
                  <div className="lds-dual-ring col"></div>
                </div>
               
              }

            </div>

          </div>

        </div> 

        <Footer/>
    </div>
  )
}

export default EditBoutique