import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AVATAR_ROOT, PHOTOS_ROOT } from '../constants/general';
import Func from '../class/Func';
import Annonces from '../class/Annonces';
import { Context } from '../class/Context'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next';
import wilaya from '../Json/wilaya.json'

const ArticleCategorie = (props) => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const context = useContext(Context)

    const id = props.data.idA

    let likes = []

    if(localStorage.likes !== undefined) likes = localStorage.likes

    const ajouterFav = async (e, id) => {

        e.preventDefault()

        e.stopPropagation()

        if(!context.session){

            Func.alert(t('t311'), "error")

            navigate('/connexion')

            return
        }

        let t = e.target

        let results = await Annonces.ajoutFavoris(id)

        if(results.success){

            localStorage.likes = results.likes

            if(results.action == 'add'){

                t.innerText = '8'
            
            }else{

                t.innerText = 'h'
            }
        }else{

            alert(results.message)
        }
    }

  return (

    <>

        <Link to={"/article/" + props.data.url} className='card bg7 pos-r'>

            <img src={ PHOTOS_ROOT + "small/" + props.data.name + ".jpg" } alt={props.data.titre} className="imga" loading='lazy'/>

            <div className='flex ai-center flex-col hicon'>

                {
                    
                    likes.includes(id)

                    ?

                    <span className='icon fz18 cl9' onClick={(e) => ajouterFav(e, props.data.idA)}>8</span>

                    
                    :

                    <span className='icon fz18 cl9' onClick={(e) => ajouterFav(e, props.data.idA)}>h</span>
                        
                }

            </div>

            {

                props.data.typeproduit == 1

                ?

                    <p className='surcommand'><span className='icon mr5 fz13'>-</span> {t('surcommande')}</p>  

                :

                    <>

                        {

                            props.data.quantity == 0

                        ?

                            <p className='soldout'>
                                <span className='icon fz13'>A</span> {t('soldout')}
                            </p>

                        :

                            <p className='dnone'></p>

                        } 

                    </>

            }
     
            <p className='p5 h cl8'>{props.data.titre}</p>

            
                <div className='flex h p5 m_flex'>

                    <p className='cl3 fz08'>{ parseInt(props.data.prix).toLocaleString('fr-FR') } {t('da')}</p>

                    { props.data.promotion > 0 && <div className='fz08'><span className='cl3 strick'>{parseInt(props.data.prix_default).toLocaleString('fr-FR')} {t('da')}</span></div>}

                </div>
            
        </Link>

    </>

  )
}

const ArticleParametres = (props) => {

    const {t} = useTranslation()

    return (

    <>     
      
      <div className=' bg7 pos-r'>

          <img src={ PHOTOS_ROOT + "small/" + props.data.name + ".jpg" } alt={props.data.titre} className="imga" loading='lazy'/>

          <div className='pl10 pt10 pr10 flex ai-center jc-between'>

              <div className='set'>

                  <div className='pos-r params '>

                      <div><span className='icon cl2 hover-cl3 b'>b</span></div>

                      <ul className='param ai-center'>
                          <Link to={'/article/' + props.data.url} className='ss-link'> <span className='icon mr5'>a</span>{t('t42')}</Link>
                          <Link to={'/modifierArticle/' + props.data.idA} className='ss-link'> <span className='icon mr5'>E</span>{t('modifier')}</Link>
                          <Link to={'/supprimer-annonce/' + props.data.idA} className='ss-link'> <span className='icon mr5'>o</span>{t('t43')}</Link>
                      </ul>

                  </div>
              </div>

  
              <p className='h cl8 fz11'>{props.data.titre}</p>

              {

                    props.data.typeproduit == 1

                ?

                    <p className='surcommand'><span className='icon fz13'>-</span> {t('surcommande')}</p>  

                :

                    <p>

                {

                    props.data.quantity == 0

                ?

                    <div className='soldout'>
                       <span className='icon fz13'>A</span> {t('soldout')}
                    </div>

                :

                    <p className='dnone'></p>

                } 


                    </p>

                }
          
          </div>
          
  
          <div className='cl8 fz11 pl10'>

            <div className='flex h p5 m_flex'>

                <p className='cl3 fz08'>{ parseInt(props.data.prix).toLocaleString('fr-FR') } {t('da')}</p>

                { props.data.promotion > 0 && <div className='fz08'><span className='cl2 mr10 strick'>{parseInt(props.data.prix_default).toLocaleString('fr-FR')} {t('da')}</span></div>}

            </div>
                     
            </div>
  
        </div>
    
    </>
    )
}

const ArticlePhares =(props) => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const context = useContext(Context)

    const id = props.data.idA

    let likes = []

    if(localStorage.likes !== undefined) likes = localStorage.likes

    const ajouterFav = async (e, id) => {

        e.preventDefault()

        e.stopPropagation()

        if(!context.session){

            Func.alert("Connectez vous pour ajouter des annonces à vos favoris", "error")

            navigate('/connexion')

            return
        }

        let t = e.target

        let results = await Annonces.ajoutFavoris(id)

        if(results.success){

            localStorage.likes = results.likes

            if(results.action == 'add'){

                t.innerText = '8'

            }else{

                t.innerText = 'h'
            }
        }else{

            alert(results.message)
        }
    }
  
    return (

    <>

        <Link to={"/article/" + props.data.url} className='article bg1 ta-center'>

        <figure><img src={ PHOTOS_ROOT + "small/" + props.data.name + ".jpg" } alt={props.data.titre} className="image" loading='lazy'/></figure>

        <div className='icon-h flex jc-between'>
            {
                likes.includes(id)

                ?

                <span className='icon cl9 fz18'  onClick={(e) => ajouterFav(e, props.data.idA)}>8</span>

                :

                <span className='icon cl1 fz18'  onClick={(e) => ajouterFav(e, props.data.idA)}>h</span>

            }
        </div>
                
        <div className='middle'>

        {

        props.data.typeproduit == 1

        ?

        <p className='sur-command'><span className='icon fz11'>-</span> {t('surcommande')}</p>  

        :

        <p>

        {

        props.data.quantity == 0

        ?

        <div className='sold-out bg9-light'>
            <span className='icon fz11'>A</span> {t('soldout')}
        </div>

        :

        <p className='dnone'></p>

        } 


        </p>

        }

        </div>

        <p className='mt10 h cl8'>{props.data.titre}</p>

        <div className='flex flex-start'>

            <p className='cl1 mt10 fz09 h fw7 prix'><span className='cl8'>{ parseInt(props.data.prix).toLocaleString('fr-FR') } {t('da')}</span> { props.data.promotion > 0 && <><span className='cl1 mr10 strick'>{parseInt(props.data.prix_default).toLocaleString('fr-FR')} {t('da')}</span></> }</p>        
        
        </div>
    </Link>

    </>

    )
}

const ArtcilePanierClient = (props) => {

    const {t} = useTranslation()

    const achat = props.achat

    return (

        <div class="brca p20 br20 mb30 bg7">

            <div className='mb10 brcb pb20'>

                <Link to={"/boutique/" + achat.panier_boutique } className='flex ai-center flex-start pos-r'>

                    <img src={ AVATAR_ROOT + achat.avatar + '.jpg'} className=" avatar-small" loading='lazy'/>
                    <h1 className='ml10 cl8 mt30 fz11 fw5 name'>{achat.name}</h1>
                        
                </Link>

                <p className='mt10 fz09'>{t('t79')} : { achat.panier_id }</p>
                
                { achat.panier_message !== '' && <p className='cl8 mt20 fz20'><span class="icon mr10">G</span>{t('t80')} : { achat.panier_message }</p> }
                
            </div>

            <div>
            {

                achat.panier_produits.map(produit => 
                    
                    <div class="flex ai-center jc-start pb10 achat brcb mb10 m_flex">
                    
                        <img src={ PHOTOS_ROOT + "small/" + produit.name + ".jpg" } className="br20 achat-img mb30m mr20" data-cmd={ achat.idP } loading='lazy'/>
                
                        <div class=" col-6 d-block M mb30m">
                            <p class="mb5 d-block">{ produit.titre }</p>
                            <p class="mb5 d-block">{t('prix')} :<span className='fz09 ml5'> { parseInt(produit.prix_total).toLocaleString('fr-FR') } {t('da')}</span></p>
                            <p class="mb5 d-block">{t('quantite')} : { produit.quantite }</p>

                            {

                                produit.option_1_type !== undefined

                                &&

                                    produit.option_1_type == "t293" || produit.option_1_type == "t294"
                                        
                                        ?
                                        
                                        produit.option_1_type !== undefined && produit.option_1_type !== null && <p class="mb5 d-block">{ t(produit.option_1_type) } : <span className="circl" style={{backgroundColor: produit.option_1_value, display: 'inline-block', margin: '0'}}></span></p>

                                        :

                                        produit.option_1_type !== undefined && produit.option_1_type !== null && <p class="mb5 d-block">{ t(produit.option_1_type) } : { produit.option_1_value }</p>

                            }

                            {

                                produit.option_id > 0

                                &&

                                    produit.type == "t293" || produit.type == "t294"
                                        
                                        ?
                                        
                                        produit.type !== undefined && produit.type !== null && <span class="mb5 d-block">{ t(produit.type) } : <span className="circl" style={{backgroundColor: produit.value, display: 'inline-block', margin: '0'}}></span></span>

                                        :

                                        produit.type !== undefined && produit.type !== null && <span class="mb5 d-block">{ t(produit.type) } : { produit.value }</span>

                            }

                            { produit.emballage == 1 && <p class="mb5 d-block">{t('t81')} : {t('toui')}</p> }
                            { produit.livraisongratuite == 1 && <p class="cl4 mb5 d-block">{t('livraisongratuite')}</p> }
                            { produit.personnalisation !== '' && <p class="mb5 d-block ">{t('perso')} : {produit.personnalisation}</p>}
                            { produit.personnalisation_prix > 0 && <p class="mb5 d-block ">{t('t82')} : { parseInt(produit.personnalisation_prix).toLocaleString('fr-FR') } {t('da')}</p>}
                        </div>
                    
                    </div>

                )

            }
            </div>

            <div className='grid2'>

                <div>

                    <h3 className='cl2 fz08 mb10 mt5'>{t('t84')} : <Moment format='DD/MM/YYYY à HH:mm' locale="fr">{ achat.panier_date }</Moment></h3>
                    <h3 className='cl2 fz10 fw5 mb10 mt5'>{t('t85')} : { achat.panier_livraison_type == 1 ? t('dom') : t('point') }</h3>
                    {
                    
                        achat.panier_livraison_point_relais > 0
                        
                        &&
                        
                        <>
                            <p className='cl2 fz09 fw5 mt5 mb10'>{t('t86')} { achat.panier_livraison_point_relais_name }</p>
                            <p className='fz09 mb10'>{ achat.panier_livraison_point_relais_adresse }</p>
                        </>
                        
                    }
                    <p className='mb10 fz10'>{t('fraisliv')} : <span className='fw7' >{ parseInt(achat.panier_livraison_prix).toLocaleString('fr-FR') } {t('da')}</span></p> 
                    <h2 className='cl2 fz11 fw5 mb10 mt20 fw7'>{t('total')} : { parseInt(parseInt(achat.panier_total) + parseInt(achat.panier_livraison_prix)).toLocaleString('fr-FR') } {t('da')}</h2>
                
                </div>
                
                <div className='fz1 bg7 p20 br20 mb30 M fz11 mb30m m_mt_20'>

                    { achat.panier_stt == 0 && <p className='cl2 fw7 mb10 ta-center'>{t('t117')}</p> }
                    
                    { achat.panier_stt == 1 && <><p className='cl2 fw7 mb10 ta-center'>{t('t118')} </p><p className='fz08 ta-center'>{achat.panier_response}</p></> }

                    { achat.panier_stt == 2 && <p className='cl4 fw7 mb10 ta-center'>{t('t119')}</p> }
                    
                    { 
                    
                        achat.panier_stt == 3
                        
                        &&
                        
                        <div>
                            <p className='cl4 fw7 mb10 ta-center'>{t('t120')}</p>

                            { achat.panier_suivi !== null && achat.panier_suivi !== '' &&  <p className='cl2 mb10 ta-center'>{t('t121')} : <strong>{ achat.panier_suivi }</strong></p>}
                        </div>

                    }

                    { 
                    
                        achat.panier_stt == 4
                    
                        && 
                        
                        <>
                        
                            <p className='cl5 fw7 mb10 ta-center m_mb_40'>{t('t101')}</p>

                            { achat.panier_remboursement == 1 && <p className='cl4 mb10 ta-center fz09'>{t('t459')}</p> }
                        
                            {
                            
                                achat.panier_avis == "0"
                            
                                ?
                                
                                <>
                                    { achat.from_validation < 30 && <Link to={"/commandes/donner-avis/" + achat.idP} className='cl2 hover-bg3 hover-cl1 M btn2 brca mb10 flex ai-center jc-start'><span className='icon mr5'>g</span>{t('t122')}</Link> }
                                </>

                                :
                                
                                <p className='btn ta-center'>Avis envoyé</p>
                            
                            }
                        
                            { (achat.panier_remboursement == 0 || achat.panier_remboursement == null) && achat.panier_reclamation !== '1' && achat.panier_reclamation !== '2' && achat.from_validation <= 7 && <Link to={"/commandes/reclamation/" + achat.idP} className='cl2 hover-bg3 hover-cl1 M btn2 brca flex ai-center jc-start'><span className='icon mr5'>à</span>{t('t123')}</Link> }
                            { achat.panier_reclamation == 1 && achat.from_reclamation > 2 && achat.from_reclamation < 7 && <Link to={"/commandes/litige/" + achat.idP} className='cl2 hover-bg3 hover-cl1 M btn2 brca flex ai-center jc-start'><span className='icon mr5'>à</span>{t('t124')}</Link> }
                            { achat.panier_reclamation == 2 && achat.from_reclamation < 7 && <Link to={"/commandes/litige/" + achat.idP} className='cl2 hover-bg3 hover-cl1 M btn2 brca flex ai-center jc-start'><span className='icon mr5'>à</span>{t('t124')}</Link> }
                        
                        </>

                    }

                    {
                    
                        achat.panier_stt == 5
                        
                        &&
                        
                        <>
                        
                            <p className='cl2 fw7 mb10'>{t('t125')}</p>
                            
                            <p className='fz08 mb20 ovf_w'>{achat.panier_response}</p>

                            {

                                achat.panier_response_image == 1

                                &&

                                <Link to={'https://taqalid.dz/image/reclamations/' + achat.panier_id + '.jpg'} target='_blank' className='mb20 d-block m_ta_center'>{t('t103')}</Link>

                            }

                            { (achat.from_reclamation >= 2 && achat.from_reclamation < 15) && <Link to={"/commandes/litige/" + achat.idP} className='cl2 hover-bg3 hover-cl1 M btn2 brca flex ai-center jc-start'><span className='icon mr5'>à</span>{t('t124')}</Link> }

                        </>
                    
                    }

                </div>
                
            </div>

        </div>
                    
    )

}

const ArtcilePanierBoutique = (props) => {
    
    const {t} = useTranslation()

    const navigate = useNavigate()

    const achat = props.achat

    console.log('achat ------------------')
    console.log(achat)

    const [expedier, setExpedier] = useState(false)
    const [codeSuivi, setCodeSuivi] = useState("")

    const validerCommande = async (id, ajuster_prix = 0) => {
    
        const results = await Func.fetch("annonces/commandes/valider", {id: id, ajuster_prix: ajuster_prix})
    
        if(results.success){
    
          Func.alert(t('t110'))
    
          navigate('/commandes/2')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const refuserLaCommande = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/refuserParClient", {id: id})
    
        if(results.success){
    
          Func.alert(t('t111'))
    
          navigate('/commandes/0')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const expedierCommande = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/expedier", {id: id, 'suivi': codeSuivi})
    
        if(results.success){
    
          Func.alert(t('t112'))
    
          navigate('/commandes/3')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const commandeLivree = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/livree", {id: id})
    
        if(results.success){
    
          Func.alert(t('t113'))
    
          navigate('/commandes/4')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const commandeEchange = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/echanger", {id: id})
    
        if(results.success){
    
          Func.alert(t('t114'))
    
          navigate('/commandes/2')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const commandeNouvelle = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/nouvelle", {id: id})
    
        if(results.success){
    
          Func.alert(t('t115'))
    
          navigate('/commandes/2')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    const refuserRetour = async (id) => {
    
        const results = await Func.fetch("annonces/commandes/refuserRetour", {id})
    
        if(results.success){
    
          Func.alert(t('t116'))
    
          navigate('/commandes/4')
    
        }else{
    
          Func.alert("ERROR")
    
        }
    
    }

    return (

        <div class="brca p20 br20 mb30 bg7 m_p10">

            <div className='mb10 brcb pb20'>

                <Link to={"/boutique/" + achat.panier_boutique } className='flex ai-center flex-start pos-r'>

                    <img src={ Func.avatar(achat.avatar)} className=" avatar-small" loading='lazy'/>
                    <h1 className='ml10 cl8 mt30 fz11 fw5 name'>{achat.name}</h1>
                        
                </Link>

                { achat.tele !== 'null' && <p className='mt10 fz09'>{t('tel')} : { achat.tele }</p> }
                { achat.adresse !== 'null' && <p className='mt10 fz09'>{t('adresse')} : { achat.adresse }, {wilaya[achat.wilaya - 1 ]?.name}</p> }
                <p className='mt10 fz09'>{t('t79')} : { achat.panier_id }</p>
                
                { achat.panier_message !== '' && <p className='cl8 mt20 fz20'><span class="icon mr10">G</span>{t('t80')} : { achat.panier_message }</p> }
                
            </div>

            <div>
            {

                achat.panier_produits.map(produit => 
                    
                    <div class="flex ai-center jc-between pb10 achat brcb mb10 m_flex">
                    
                        <div className='flex ai-center jc-start col-6 m_flex'>

                            <img src={ PHOTOS_ROOT + "small/" + produit.name + ".jpg" } className="br20 achat-img mb30m mr5" loading='lazy'/>
                
                            <div class=" col-6 d-block M mb30m">
                                <p class="mb5 d-block">{ produit.titre }</p>
                                <p class="mb5 d-block">{t('prix')} :<span className='fz09 ml5'> { parseInt(produit.prix_total).toLocaleString('fr-FR') } {t('da')}</span></p>
                                <p class="mb5 d-block">{t('quantite')} : { produit.quantite }</p>
                                
                                {

                                produit.option_1_type

                                &&

                                    produit.option_1_type == "t293" || produit.option_1_type == "t294"
                                        
                                        ?
                                        
                                        produit.option_1_type !== undefined && produit.option_1_type !== null && <p class="mb5 d-block">{ t(produit.option_1_type) } : <span className="circl" style={{backgroundColor: produit.option_1_value, display: 'inline-block', margin: '0'}}></span></p>

                                        :

                                        produit.option_1_type !== undefined && produit.option_1_type !== null && <p class="mb5 d-block">{ t(produit.option_1_type) } : { produit.option_1_value }</p>

                                }

                                {

                                produit.option_id !== 0

                                &&

                                    produit.type == "t293" || produit.type == "t294"
                                        
                                        ?
                                        
                                        produit.type !==  undefined && produit.type !== null && <span class="mb5 d-block">{ t(produit.type) } : <span className="circl" style={{backgroundColor: produit.value, display: 'inline-block', margin: '0'}}></span></span>

                                        :

                                        produit.type !== undefined && produit.type !== null && <span class="mb5 d-block">{ t(produit.type) } : { produit.value }</span>

                                }
                                
                                { produit.emballage == 1 && <p class="mb5 d-block">{t('t81')} : {t('oui')}</p> }
                                { produit.livraisongratuite == 1 && <p class="cl4 mb5 d-block">{t('livraisongratuite')}</p> }
                                { produit.personnalisation !== '' && <p class="mb5 d-block">{t('perso')} : {produit.personnalisation}</p>}
                                { produit.personnalisation_prix > 0 && <p class="mb5 d-block">{t('t82')} : { parseInt(produit.personnalisation_prix).toLocaleString('fr-FR') } {t('da')}</p>}
                            </div>

                        </div>

                        { achat.panier_stt == 0 && achat.panier_produits.length > 1 && <Link to={"/commandes/retirer-article/" + produit.idP } className='btn bg8 cl1 fz07 p5'><span className='icon mr10'>X</span>{t('t83')}</Link> }
                    
                    </div>

                )

            }
            </div>

            <div className='flex'>

                <div className='col-6'>

                    <h3 className='cl2 fz08 mb10 mt5'>{t('t84')} : <Moment format='DD/MM/YYYY à HH:mm' locale="fr">{ achat.panier_date }</Moment></h3>
                    <h3 className='cl2 fz10 fw5 mb10 mt5'>{t('t85')} : { achat.panier_livraison_type == 1 ? t('dom') : t('point') }</h3>
                    {
                    
                        achat.panier_livraison_point_relais > 0
                        
                        &&
                        
                        <>
                            <p className='cl2 fz09 fw5 mt5 mb10'>{ t('t86')} { achat.panier_livraison_point_relais_name }</p>
                            <p className='fz09 mb10'>{ achat.panier_livraison_point_relais_adresse }</p>
                        </>
                        
                    }
                    <p className='mb10 fz10'>{t('fraisliv')} : <span className='fw7' >{ parseInt(achat.panier_livraison_prix).toLocaleString('fr-FR') } {t('da')}</span></p> 
                    <h2 className='cl2 fz11 fw5 mb10 mt20 fw7'>{t('total')} : { parseInt(parseInt(achat.panier_total) + parseInt(achat.panier_livraison_prix)).toLocaleString('fr-FR') } {t('da')}</h2>
                
                </div>
                
                <div className='fz1 bg7 p5 br20 M fz11 mb30m col-4 m_mt_40'>
                    
                    { 

                        /* En Attente ---------------------------- */
                        /* En Attente ---------------------------- */

                        achat.panier_stt == 0
                    
                        &&
                        
                        <>
                            { achat.panier_livraison_prix_edit == '0' && <p className='cl1 bg3 mb10 ta-center btn fz09' onClick={() => validerCommande(achat.idP, 0) }>{t('t87')}</p> }
                            <Link to={'/commandes/annuler-commande/' + achat.idP} className='cl1 bg8 mb10 ta-center btn fz09 d-block'>{t('t88')}</Link>

                            {
                                achat.panier_livraison_prix_edit == '0'

                                    ?

                                        <Link to={'/commandes/ajuster-livraison/' + achat.idP + '?montant=' + achat.panier_livraison_prix} className='cl1 bg10 ta-center btn fz09 d-block'>{t('t89')}</Link>
                                    
                                    :

                                        <>
                                            <p className='cl1 bg3 mb10 ta-center btn fz09' onClick={() => validerCommande(achat.idP, 1) }>{t('t90')}</p>
                                            <p className='cl1 bg10 ta-center btn fz09' onClick={() => refuserLaCommande(achat.idP) }>{t('t91')}</p>
                                        </>
                                        
                            }

                        </>
                        
                    }

                    {/* Annulée ---------------------------- */}
                    {/* Annulée ---------------------------- */}

                    { achat.panier_stt == 1 && <><p className='cl2 fw7 mb10 ta-center'>{t('t92')}</p><p className='fz08 ta-center'>{achat.panier_response}</p></> }
                    
                    {/* Validée ---------------------------- */}
                    {/* Validée ---------------------------- */}
                    { achat.panier_stt == 2
                    
                        &&

                        <>
                            <p className='cl4 fw7 mb10 ta-center'>{t('t93')}</p>

                            { !expedier && <p className='cl1 bg3 mb20 ta-center btn fz09' onClick={() => setExpedier(true) }>{t('t94')}</p> }

                            {

                                expedier

                                &&

                                <div className='form'>

                                    <p className='mb10'>{t('t95')}</p>
                                    <input type="text" placeholder='Code de suivi' onChange={ e => setCodeSuivi(e.target.value)} value={codeSuivi} style={{marginBottom: '10px'}}/>
                                    <p className='cl1 bg3 mb20 ta-center btn fz09' onClick={() => expedierCommande(achat.idP) }>{t('t96')}</p>

                                </div>

                            }

                            <p className='cl2 ta-center fz09 brc1solid brc2 btn'><Link to={'https://taqalid.dz/bordereau/index.php?panier_id=' + achat.panier_id} target='_blank' className='cl2'>{t('t97')}</Link></p>
                        
                        </>

                    }

                    {/* Expédiée ---------------------------- */}
                    {/* Expédiée ---------------------------- */}

                    { achat.panier_stt == 3
                    
                        &&

                        <>
                            <p className='cl4 fw7 mb10 ta-center'>{t('t98')}</p>

                            <p className='cl1 bg3 mb20 ta-center btn fz09' onClick={() => commandeLivree(achat.idP) }>{t('t99')}</p>
                            <p className=' bg3 mb20 ta-center btn fz09'><Link to={'/commandes/retourner-commande/' + achat.idP} className='cl1'>{t('t100')}</Link></p>
                        </>

                    }

                    {/* Livrée ---------------------------- */}
                    {/* Livrée ---------------------------- */}                            
                    { 
                    
                        achat.panier_stt == 4
                    
                        && 
                        
                        <>
                        
                            <p className='cl5 fw7 mb10 ta-center'>{t('t101')}</p>

                            { achat.panier_remboursement == 1 && <p className='cl4 mb10 ta-center fz09'>{t('t459')}</p> }
                        
                        </>

                    }

                    {/* Réclamation ---------------------------- */}
                    {/* Réclamation ---------------------------- */}     

                    {
                    
                        achat.panier_stt == 5
                        
                        &&
                        
                        <>
                        
                            {/* <p className='cl2 fw7 mb10'>Retournée à l'expéditeur</p>
                            
                            <p className='fz08 mb20'>{achat.response}</p> */}

                            <p className='cl2 fw7 mb10 m_ta_center'>{t('t102')}</p>
                            
                            <p className='fz08 mb20 ovf_w m_ta_center'>{achat.panier_response}</p>

                            {

                                achat.panier_response_image == 1

                                &&

                                <Link to={'https://taqalid.dz/image/reclamations/' + achat.panier_id + '.jpg'} target='_blank' className='mb20 d-block m_ta_center'>{t('t103')}</Link>

                            }

                            <Link to={"/envoyer-message/" + achat.panier_user} className='cl1 bg8 btn brca d-block ta-center fz08 mb30'>{t('t104')}</Link>
                            <span className='cl1 bg3 btn brca d-block ta-center fz08 mb5' onClick={e => commandeEchange(achat.idP)}>{t('t105')}</span>
                            <span onClick={e => commandeNouvelle(achat.idP)} className='cl1 bg3 btn brca d-block ta-center fz08 mb5'>{t('t106')}</span>
                            {/* <span onClick={e => commandeRembourssement(achat.idP)} className='cl1 bg3 btn brca d-block ta-center fz08 mb5'>Procéder à un remboursement</span> */}
                            <Link to={"/commandes/remboursement/" + achat.idP} className='cl1 bg3 btn brca d-block ta-center fz08 mb5'>{t('t107')}</Link>
                            <span onClick={e => refuserRetour(achat.idP)} className='cl1 bg9 btn brca d-block ta-center fz08'>{t('t108')}</span>
                        
                        </>
                    
                    }

                    
                    {/* Non Livrée ---------------------------- */}
                    {/* Non Livrée ---------------------------- */} 

                    {

                        achat.panier_stt == 6
                        
                        &&

                        <>
                        
                        <p className='cl2 fw7 mb10 ta-center'>{t('t109')}</p>
                        <p className='fz08 mb20 ta-center'>{achat.panier_response}</p>
                        
                        </>

                    }

                </div>
                
            </div>

        </div>

    )

}

export { ArticleCategorie, ArticleParametres, ArticlePhares, ArtcilePanierClient, ArtcilePanierBoutique }  