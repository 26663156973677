import React, { useContext, useEffect, useState } from 'react'
import { APP_NAME, PHOTOS_ROOT, VIDEOS_ROOT, WEBROOT } from '../constants/general'
import { Header, HeaderBox , Footer } from '../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Context } from '../class/Context'
import Annonces from '../class/Annonces'
import Func from '../class/Func'
import { ArticleCategorie } from '../components/Articles'
import User from '../class/User'
import wilaya from '../Json/wilaya.json'
import { useTranslation } from 'react-i18next'

function Article() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const [articlePrix, setarticlePrix] = useState(0)

  const [personnaliser, setPersonnaliser] = useState(0)

  const [emballage, setEmballage] = useState(0)
  
  const [AutreAnnonces, setAutreAnnonces] = useState('')

  const [selectedWilaya, setSelectedWilaya] = useState(0)

  const { id } = useParams()

  const [articleinfos, setArticleInfos] = useState("")

  const [photos, setPhotos] = useState([])
  
  const context = useContext(Context)

  const [details, setDetails] = useState(true);

  const [variations, setVariations] = useState(false);
  
  const [politique, setPolitique] = useState(false);

  const [error, setError] = useState('');

  const [array, setArray] = useState(false)

  const [radio, setRadio] = useState("")
  
  const [selected, setSelected] = useState("")

  const [qnt, setQnt] = useState(1)

  const [qntMax, setQntMax] = useState(20)
 
  const [mapersonnalisation , setMapersonnalisation] = useState("")

  const [boutique , setBoutique] = useState([])

  const [panier , setPanier] = useState([])

  const [option_1 , setOption_1] = useState(0)

  const [option_2 , setOption_2] = useState(0)

  const [option , setOption] = useState({})

  const translateThisText = (e, text) => {

    googleTranslateElementInit()
    
  }

  const toggleShowVar= () =>{

    setVariations(!variations)
  }

  const toggleShowDetails = () =>{

    setDetails(!details)
  }

  const toggleShowPolitique = () =>{

    setPolitique(!politique)
  }

  const setSelectedVar = (v) =>{
    
    setPersonnaliser(v)

 }

  const setChecked = (o) =>{
    
  setEmballage(o)

  }

  const ArticleInfos = async () => {

    let results = await Annonces.annonceSingle(id)

    if(results.success){
  
      setArticleInfos(results.data)

      setarticlePrix(results.data.prix)

      if(results.data.typeproduit == 0) setQntMax(results.data.quantity)

      setPhotos(results.photos)

      setArray(results.options)

      AutreArticles(results.data.user_id)

      document.title = results.data.titre + " - " + APP_NAME
  
    }else{

      navigate("/404")
    }
  
  } 

  const AutreArticles = async (id_user) => {

    let results = await Annonces.AutresArticlesbyUser(id_user)

    if(results.success){
  
      setAutreAnnonces(results.data)
 
    }

  }

  let likes = []

  if(localStorage.likes !== undefined) likes = localStorage.likes

  const ajouterFav = async (e, id) => {

    e.preventDefault()

    e.stopPropagation()

    if(!context.session){

        Func.alert(t('t311'), "error")

        navigate('/connexion')

        return
    }

    let t = e.target

    let results = await Annonces.ajoutFavoris(id)

    if(results.success){

        localStorage.likes = results.likes

        if(results.action == 'add'){

          t.innerText = '8'

        }else{

          t.innerText = 'h'
        }
    }else{

        alert(results.message)
    }
  }

  const addPanier = async (e) =>{

    e.preventDefault()
    
    // if(option.option_id == undefined && array != false){

    //   Func.alert('Veuillez choisir une option pour ajouter cet article au panier')

    //   return

    // }

    let delivery = 1

    if(localStorage.getItem('user_type') == 2){

      Func.alert(t('t312'))

      return

    }

    if(array != false && option_1 == 0 && option_2 == 0){

      Func.alert(t('t313'))

      return

    }

    if(array.length > 0 && Object.keys(option).length == 0 && option_2 == 0){

      Func.alert(t('t314'))

      return

    }

    if(articleinfos.p_obligatoire == 1 && mapersonnalisation == ""){

      Func.alert(t('t315'))

      return

    }

    if(array == false) setOption({option_id: 0, idO: 0, type: 'Option', value: 'sans'})

    if(localStorage.getItem('wilaya') == null){

      Func.alert(t('t316'))

      return

    }

    const prix = await User.LivraisonPrix(articleinfos.idU)
    
    if(prix.success == false || (prix.success == true && prix.dom == '/' && prix.relais == '/' && prix.moimeme == '0')){

      Func.alert(t('t317'))
      
      setError(prix.message)

      window.scrollTo(0, 0)

      return false
     
    }

    if(prix.success == true){

      if(prix.dom == '/' && prix.relais !== '/'){
        delivery = 2
      }

      if(prix.dom !== '/'){
        delivery = 1
      }
     
    }

      // if( localStorage.getItem('panier') == undefined){

      if(localStorage.getItem('panier') == undefined) localStorage.setItem('panier', '[]')

      let panier = JSON.parse(localStorage.getItem('panier'));

      // if(!panier.includes(articleinfos.idU)){ 

      let obj = {
        // prix : articleinfos.promotion !== 0 ? (parseInt( articleinfos.promotion) + parseInt(articleinfos.prixpersonnaliser)) :  (parseInt(selected.optionprix) + parseInt(articleinfos.prixpersonnaliser)) || 0 , 
        prix : radio == "" ? ( personnaliser == 1 ? (parseInt(articlePrix) + parseInt(articleinfos.prixpersonnaliser)) : articlePrix) : ( personnaliser == 1 ? (parseInt(articlePrix) + parseInt(articleinfos.prixpersonnaliser)) : selected.prix ), 

        prix_option : personnaliser == 1 ? (parseInt(option.prix) + parseInt(articleinfos.prixpersonnaliser)) : option.prix,

        prix_personnaliseer : articleinfos.prixpersonnaliser, 
  
        prixpromo: articleinfos.promotion,
        // prix_sans_variation:  personnaliser == 1 ?  (articleinfos.promotion !== 0 ? (parseInt( articleinfos.promotion)+ parseInt(articleinfos.prixpersonnaliser)) : (parseInt(articleinfos.prix ) + parseInt(articleinfos.prixpersonnaliser)) ) : articleinfos.prix ,
        prix_sans_variation: personnaliser == 1 ?  (parseInt(articleinfos.prix ) + parseInt(articleinfos.prixpersonnaliser))  : articleinfos.prix ,

        delivery: delivery,
        prixLivraison: 0,
        dom: prix.dom,
        relais : prix.relais,
        moimeme : prix.moimeme,
        id_user: articleinfos.user_id,
        article_titre: articleinfos.titre, 
        idO: option.idO,
        option_type: option.type,
        option_value: option.value,
        annonce_id: id,
        personnaliser,
        emballage,
        qnt,
        reference: selected.optioname,
        mapersonnalisation,
        personnalisation : articleinfos.personnalisation,
        autre_partenaire : articleinfos.autre_partenaire,
        boutique_img: photos[0].name,
        id_user:articleinfos.user_id,
        boutique_id: articleinfos.idU,
        boutique_name: articleinfos.name,
        boutique_avatar: articleinfos.avatar,
        livraison: articleinfos.livraison,
        livraison_gratuite: articleinfos.livraisongratuite,
        poids: articleinfos.poids,
        livraison_indiv: articleinfos.livraison_indiv,
        livraison_special: articleinfos.livraison_special,
        livraison_special_prix: articleinfos.livraison_special_prix,
        poids_prix: articleinfos.poids <= 5 ? 0 : articleinfos.poids - 5
      
      }

      panier.push(obj)

      localStorage.setItem('panier', JSON.stringify(panier))

      navigate('/panier')
  
  }

  const selectSousOpt = async (item) =>{

    setOption_2(item.option_id)

    setOption(item)

    setarticlePrix(item.prix)

    if(articleinfos.typeproduit == 0){

      setQntMax(item.qtt)

      if(item.qtt == 0) Func.alert(t('t318'))

    }

  }

  const selectOpt = async (item) =>{

    setOption_1(item.option_id)

    if(item.prix !== '/'){

      setOption(item)

      setarticlePrix(item.prix)

      if(articleinfos.typeproduit == 0){

        setQntMax(item.qtt)

        if(item.qtt == 0) Func.alert(t('t318'))

      }

    }

  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "fr",
        includedLanguages: 'ar,en',
        sectionalNodeClassName: 'translate',
        controlNodeClassName: 'translate-lyrics',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
      },
      "google_translate_element"
    )
  }

  const setSelectedWilayaHere = (w) => {

    if(w >= 1 && w <= 58){
      
      setSelectedWilaya(w)

      localStorage.setItem('wilaya', w)

    }

  }

  const zoom = (e, index, type) => {

    var this_index = index

    var imgs = document.querySelectorAll('.zoom_imgs')

    var mask = document.createElement('div');
    mask.className = 'mask';
    mask.id = 'mask';

    if(type == 1){
      mask.innerHTML = '<img src="'+ e.target.src+'" id="img-zm"/>';
    }else{
      mask.innerHTML = '<video src="'+ e.target.src+'" id="img-zm" controls/>';
    }

    var btn = document.createElement('div');
    btn.innerHTML = "<span class='btn btn-prev icon cl1 on_prev'>k</span> <span class='btn btn-next icon cl1 on_next'>l</span>"

    var exit = document.createElement('span');
    exit.className = 'exit cl1 icon fz2';
    exit.innerText = 'x';
    exit.addEventListener('click', () => document.body.removeChild(mask));

    mask.appendChild(exit)

    if(imgs.length > 1) mask.appendChild(btn)

    document.body.appendChild(mask)

    let t_src = ''

    if(imgs.length <= 1) return

    document.querySelector('.on_next').addEventListener('click', () => {

        if(this_index == imgs.length - 1) return        
        
        this_index = this_index + 1

        t_src = document.querySelector('.img-n'+ this_index).src

        if(document.querySelector('.img-n'+ this_index).nodeName == 'IMG'){

          document.getElementById('img-zm').outerHTML = '<img src="'+ t_src+'" id="img-zm"/>'

        }else{

          document.getElementById('img-zm').outerHTML = '<video src="'+ t_src+'" id="img-zm" controls/>'

        }

    })

    document.querySelector('.on_prev').addEventListener('click', () => {

        if(this_index == 0) return

        this_index = this_index - 1

        t_src = document.querySelector('.img-n'+ this_index).src

        console.log(document.querySelector('.img-n'+ this_index).nodeName)

        if(document.querySelector('.img-n'+ this_index).nodeName == 'IMG'){

          document.getElementById('img-zm').outerHTML = '<img src="'+ t_src+'" id="img-zm"/>'

        }else{

          document.getElementById('img-zm').outerHTML = '<video src="'+ t_src+'" id="img-zm" controls/>'

        }

    }, false)

  }

  useEffect((id) => {

    document.title = "Article - " + APP_NAME

    window.scrollTo(0, 0)

    if(localStorage.getItem('wilaya') !== null) setSelectedWilaya(localStorage.getItem('wilaya'))
    
    ArticleInfos()

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js"
    );
    document.body.appendChild(addScript)

    window.googleTranslateElementInit = googleTranslateElementInit;
    
  }, [id])

  return (

    <div>
        <Header/>

        <HeaderBox/>

        {
        
        articleinfos

        ?
          <div className='bg1 '> 

            <div className='c flex mt30 ar m_mt_0'> 

              <div className='slider sticky col-6 single'>
                
                <div className='hicon flex jc-between '>
                {
                  likes.includes(articleinfos.idA)

                  ?
                  <span className='icon fz18 cl9' onClick={(e) => ajouterFav(e, articleinfos.idA)}>8</span>

                  :

                  <span className='icon fz18 cl9' onClick={(e) => ajouterFav(e, articleinfos.idA)}>h</span>

                }

                </div>

                {

                  photos

                ?
                  <div className='m_p10'>
                    
                    <img src={ PHOTOS_ROOT + "full/" + photos[0].name + ".jpg" } className={'image mb20 zoom_imgs img-n0'} onClick={ e => zoom(e, 0, 1) }/>
            
                    <div className='grid5 more_images m_grid4'>

                      {

                        photos.map((image, index) => {

                          return(

                            <>

                              {

                                index > 0

                                &&
                                
                                <div key={image.idI}>

                                  { image.type == 1 && <img src={PHOTOS_ROOT + 'full/' + image.name + '.jpg'} className={'img mb20 zoom_imgs img-n' + index } onClick={ e => zoom(e, index, 1) } loading='lazy'/> }
                                  { image.type == 2 && <video src={VIDEOS_ROOT + image.name} controls="false" style={{objectFit: 'cover'}} className={'img t_video mb20 zoom_imgs img-n' + index} onClick={ e => zoom(e, index, 2) }/> }

                                </div>

                              }

                            </>

                          )

                          }

                        )

                      }

                    </div>
                  </div>
                :
                  <div className='flex jc-center mt50 mb50'>
                    <div className="lds-dual-ring col"></div>
                  </div>

                }

                          
                <div className='pos-r m_p10'>

                  <Link to={"/boutique/" + articleinfos.user_id} className='flex ai-center flex-start mt10 mb20 m_flex'>

                    <img src={ Func.avatar(articleinfos.avatar) } className="avatar" loading='lazy'/>

                    <div>
                      <h3 className='ml20 cl8'>{articleinfos.name}</h3>
                      <div className='ml20 flex ai-center'>{ Func.note(articleinfos.note) } <span className='ml10 fz08'>({articleinfos.nbr_note} {t('t31')})</span></div>
                    </div>

                  </Link>
                  
                </div>

              </div>

              <div className='details ml40 col-4 m_p10'>

                  <h1 className='h_mr fw5 fz15'>{articleinfos.titre}</h1>  

                  <p className='cl8 mt20'>

                  {

                  articleinfos.typeproduit == 1

                  ?

                  <p className='cl8'> <span className='icon mr5'>-</span> {t('t319')}</p>  

                  :

                    <p className='cl8'>

                      {

                        articleinfos.quantity == 0

                      ?
                    
                        <p className='cl9'> <span className='icon mr5'>A</span> {t('t320')}</p>

                      :

                        <p className='cl8'><span className='icon mr5'>/</span> {t('t321')} <span className='cl4 fw6'> {articleinfos.quantity} {t('t322')}</span></p>

                      }
                    
                    </p>

                  }
                  </p>

                  {

                    articleinfos.promotion > 0 && option_1 == 0

                    &&

                    <p className='mt20'>
                    
                      <span className='cl3 strick mr10'>{ parseInt(articleinfos.prix_default).toLocaleString('fr-FR') } DA</span>
                      <span className='cl1 bg3 p5'>-{ articleinfos.promotion } %</span>

                    </p>

                  }

                  {

                  articleinfos.promotion > 0 && option_1 !== 0

                  &&

                  <p className='mt20'>

                    <span className='cl1 bg3 p5'>-{ articleinfos.promotion } %</span>

                  </p>

                  }

                  <p className='mt10 fz15 cl3 fw5'>{ parseInt(articlePrix).toLocaleString('fr-FR') } DA</p>

                  <div className='mt20 pos-r'>

                    <p className='cl8 mb20 fz11 fw6 des'>{t('t323')}</p>

                    <p className='cl2 col-8 mt20 mb20 translate p'>{articleinfos.description}</p>

                    <div id="google_translate_element" className='mt20'></div>

                    <p onClick={e => translateThisText(e, articleinfos.description)}className='cl3 fz09'>{t('t324')}</p>

                  </div>
              
                  <form method='POST' className='mt20 form' onSubmit={(e) => addPanier(e, setError, navigate)}  >

                      <>
          
                        { array == false ? <span></span> : <p className='cl8 mb20 mt40 fz11 fw6 pos-r des cp' onClick={toggleShowVar}>{t('t290')}<p className='icon fz11 cp cl8 fw7 hover-cl3 up flex-end' onClick={toggleShowVar}>{variations ? "2" : "2"}</p></p> }
                        
                        {

                          array == false
                          
                          ?
                          
                          <span></span>

                          :
                                        
                          <>
                          
                            <p className='cl8 mb30 fz09 ml10'>{t('t326')}</p>
                      
                            <h3 className='ml10 mb40'>{t(array[0].type)}</h3>

                            {

                              array 

                              && 

                              array.map((item, i )=> {

                                return (

                                  <>

                                    {

                                      item.parent_id == ''

                                      ?
                                                            
                                        item.type == "t293" || item.type == "t294"
                                        
                                        ?
                                        
                                          <span key={i} className={`circl btn ${item.option_id == option_1 ? ' brc3 brc3solid' : ''}`} onClick={e => selectOpt(item) } style={{backgroundColor: item.value}}></span>

                                        :

                                          <span key={i} className={`var_stl br15 p10 btn ${item.option_id == option_1 ? ' bg3' : ' bg7'}`} onClick={e => selectOpt(item) }>{ item.value }</span>

                                      :

                                      <></>

                                    }

                                  </>

                                )
                                
                              })

                              }

                              <div className='mt40'>

                              {

                                array && option_1 !== 0

                                &&

                                array[1].parent_id !== '' && <h3 className='ml10 mb40'>{t(array[1].type)}</h3>

                              }

                              {

                                array 

                                &&

                                array.map((item, i)=> {

                                  return (

                                    <>

                                      {

                                        item.parent_id !== '' && item.parent_id == option_1

                                        &&

                                          <>

                                            {
                                                            
                                              item.type == "t293" || item.type == "t294"
                                              
                                              ?
                                              
                                                <span key={i} className={`circl btn ${item.option_id == option_2 ? ' brc3 brc3solid' : ''}`} style={{backgroundColor: item.value}} onClick={e => selectSousOpt(item)}></span>

                                              :

                                              <span key={i} className={`var_stl br15 p10 btn ${item.option_id == option_2 ? ' bg3' : 'bg7'}`} onClick={e => selectSousOpt(item)}>{ item.value }</span>
                                            
                                            }

                                          </>

                                      }

                                    </>

                                  )
                                  
                                })

                              }

                              </div>

                          </>

                        }

                            <div className='mt50'>

                            <p className='cl8 fw6 fz11 mb20 ml5'>{t('quantite')}</p>

                            { qntMax == 0 && <p className='error'>{t('t327')}</p> }

                            <input type="number" name="quantity" min="1" className='bg7' value={qnt} onChange={e => setQnt(e.target.value)} max={qntMax}/>

                            </div>
                      
                        {

                          articleinfos.personnalisation == 1

                        ?

                         articleinfos.p_obligatoire == 1  

                          ?

                          <div className='mt20'>
                          
                           <label className='fz111 m0'><input type="checkbox" name='personaliser' checked disabled className="mr10" value={1}/>{t('t328')}</label>

                              <div>

                                {
                                  
                                  articleinfos.prixpersonnaliser == 0

                                  ?

                                  <p className='mt10 p10 mb10 cl4'>{t('t329')}</p>

                                  :

                                  <p className='mt10 p10 mb10'>{t('prix')} : <span> {articleinfos.prixpersonnaliser}</span> DA</p>

                                }                           

                                <textarea name="mapersonnalisation" className='bg7 h-120' placeholder={articleinfos.descpersonnaliser} onChange={e => setMapersonnalisation(e.target.value)} required={true}/>

                              </div>
                            
                          
                          </div>

                          :

                           <div className='mt20'>
                          
                           <p className='m0'><input type="checkbox" name='personaliser' className="mr10" value={1} onChange={e => setSelectedVar(e.target.checked)} />{t('t328')}</p>
                            
                            {

                              personnaliser == 1

                              &&

                              <div>

                                {
                                  articleinfos.prixpersonnaliser == 0

                                  ?

                                  <p className='mt10 p10 mb10 cl4'>{t('t329')}</p>

                                  :

                                  <p className='mt10 p10 mb10'>{t('prix')} : <span> {articleinfos.prixpersonnaliser}</span> DA</p>

                                }                           

                                <textarea name="mapersonnalisation" className='bg7 h-120' placeholder={articleinfos.descpersonnaliser} onChange={e => setMapersonnalisation(e.target.value)} required={true} />

                              </div>
                            }
                          
                          </div>
                        :
                          <p className='dnone'></p>
                        } 

                      </>
                       


                      <p className='cl8 mb20 mt20 fz11 fw6 pos-r des cp' onClick={toggleShowDetails}>{t('t330')}<p className='icon fz11 cp cl8 fw7 hover-cl3 up flex-end' onClick={toggleShowDetails}>{details ? "2" : "2"}</p></p>

                      {
                      
                        details

                      &&

                        <div className='grid2 mb30 p5 fz09'>                  

                        {

                          articleinfos.typeproduit == 1

                        ?

                          <p className='cl8'> <span className='icon mr5'>-</span> {t('t319')}</p>  

                        :

                          <p className='cl8'>

                            {

                              articleinfos.quantity == 0

                            ?
                          
                              <p className='cl9'> <span className='icon mr5'>A</span> {t('t320')}</p>

                            :

                            <p className='cl8'><span className='icon mr5'>/</span> {t('t321')}</p>

                            } 
                          
                          
                          </p>

                        }


                        {

                          articleinfos.personnalisation == 1

                        &&

                          <p className='cl8'> <span className='icon mr5'>E</span> {t('t331')}</p>

                        } 

                    

                        {

                          articleinfos.handmade == 1

                        &&

                          <p className='cl8'> <span className='icon mr5'>n</span> {t('t165')}</p>

                        } 

                    
                        </div>
                      }
                    
                    
                

                      <div className='flex mb20'>

                        <div className='mt20'>

                          <p className='cl8 fz11 fw6 mb10'>{t('t332')} 
                          
                          {
                          
                            articleinfos.livraison == 0
                            
                            ?
                            
                            <span> {articleinfos.autre_partenaire}</span>
                            
                            :
                            
                            articleinfos.livraison == 1

                            ?

                              <span> {t('t333')}</span>

                            :

                            <span> { articleinfos.livraison }</span>
                          
                          }
                          
                          </p>

                          {

                            articleinfos.poids > 0

                            && 

                            <p className='cl8 mb20 mt20'>{t('t155')} : <span className='fw5'>{articleinfos.poids}</span></p>

                          }

                          {

                            articleinfos.livraison_special == 1 && articleinfos.livraison_special_prix > 0

                            && 

                            <p className='cl8 mb20 mt20'>{t('t334')} : <span className='fw5'>{articleinfos.livraison_special_prix} DA</span></p>

                          }

                          <p className='cl8 mb10'>{t('t200')} : <span className='fw5'>{t(articleinfos.delaislivraison)}</span></p>
                          
                          {

                            articleinfos.livraisongratuite == 1

                          &&

                            <span className='cl4 bg4-light br8 p10 fw6 d-i-block mt10'>{t('livraisongratuite')}</span>

                          } 

                        </div>

                        {

                          articleinfos.typeproduit == 1

                          &&


                          <div className='mt20'>

                            <p className='cl8 fz11 mb10 fw6'>{t('t142')}</p>

                            <p className='mb10'>{t(articleinfos.conception)}</p> 

                          </div>
                        

                        }

                    
                      </div>

                      {

                       articleinfos.emballage == 1 

                      ?
                        <div>
                          <p><input type="checkbox" name='emballage' className="mr10" value={1} onChange={e => setChecked(e.target.checked)}/>{t('t166')}</p>
                        </div>
                      :
                        <p className='dnone'></p>

                      }  

                      <div>

                          {

                            selectedWilaya == 0
                            
                            &&

                            <div className='d-block'>

                              <select type="text" name="wilaya" required="true" className='bg7 wilayaId mb20i' onChange={(e) => setSelectedWilayaHere(e.target.value)}>
                      
                                <option value={0}>{t('t335')}</option>
                                {
                            
                                  wilaya.map((getwilaya, index)  => (

                                    <option value={getwilaya.id} key={index}>{getwilaya.name}</option>

                                  ))
                                }
                          
                              </select>

                            </div>

                          }

                          <input type='submit' className='m0 mb30m btn brc5 brc1solid bg3 cl1' value={t('t336')}/>

                      </div>

                    </form>
              </div>
              
            </div>

            <div className='c mt80 mb40 m_mt_0'>

              <p className='h1 mb30'>{t('t337')}</p>

              {

                AutreAnnonces

              ?
                <div className='pos-r'>

                  <div className="grid5 cards articles mb30">

                    {AutreAnnonces.map(element => <ArticleCategorie data={element} key={element.idA} />)}

                  </div>

                </div>

              : 

                <div className='flex jc-center mt50 mb50'>
                    <div className="lds-dual-ring col"></div>
                </div>

              }
             
            </div>
          
            <div className='mt80 c mb30 m_p10 m_mt_0'>

              <p className='h1 mb20'>{t('t201')}</p>

              {

                articleinfos.politique == "1"

              ?
                
                <p className='mb10 lh'>{articleinfos.politiquetext.slice(0, 270) }

                {
                  politique 
                  
                && 

                  (articleinfos.politiquetext.length > 270 && (articleinfos.politiquetext.slice(270)))
                  
                } 

                {

                  articleinfos.politiquetext.length <= 270

                ?

                  <p className='dnone'></p>

                :
                
                  <p onClick={toggleShowPolitique} className='cl3 mb30 cp'>{politique ? t('t203') : t('t202')}<span className='icon'>2</span></p>

                }

                </p>

              :

                <div className='fz11 mb20'>
                  
                  <li>{t('t204')}</li>
                  <li>{t('t205')}</li>
                  <li>{t('t206')}</li>
                  <li>{t('t207')}</li>
                  <li>{t('t207_2')}</li>
                  
                </div> 

              }

            </div>  

          </div>
        :
        
          <div className='flex jc-center mt50 mb50'>
            <div className="lds-dual-ring col"></div>
          </div>

        }
    
        <Footer/>
    </div>
  )
}

export default Article