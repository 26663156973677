import { API_ROOT, API_SECRET } from '../constants/general';

class  Categories{

    static getCategories = () => {

        let data = fetch(API_ROOT + "categories/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                lng: localStorage.getItem('i18nextLng')
            })
            
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getSousCategories = (parent) => {

        let data = fetch(API_ROOT + "categories/getSous", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                parent,
                lng: localStorage.getItem('i18nextLng')
            })
            
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getCategorieByUrl = (url, limit, options) => {

        let data = fetch(API_ROOT + "categories/categorieByUrl", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,    
                url:url,
                limit: limit,
                options: options,
                lng: localStorage.getItem('i18nextLng')
            })
            
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data

        })

        return data

    }

}

export default Categories