import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import langueFR from "./locales/fr.json"
import langueEN from "./locales/en.json"
import langueAR from "./locales/ar.json"

const resources = {
    fr: {translation: langueFR},
    en: {translation: langueEN},
    ar: {translation: langueAR}
}

i18n.use(LanguageDetector).use(initReactI18next).init({

    debug: false,
    //lng: 'fr',
    fallbackLng: "fr",
    resources

})