import React from 'react'
import { Link } from 'react-router-dom';
import { ROOT } from '../constants/general';

const AllCategories = (props) => {

    return(

        <Link to={"/categorie/" + props.data.url} className='art' key={props.data.idC}>
            <div className='figure'><img src={ ROOT + "gla-adminer/uploads/article/small/" + props.data.image } loading='lazy'/></div>
            <p className='mt10 h cl2'>{props.data.name}</p>
        </Link>

    )

}

const SousCategories = (props) =>{

    return(
        
    <div>
        <Link to={props.data.url} className='art'>
            <div className='figure'><img src={ ROOT + "small/" + props.data.image + ".jpg" } loading='lazy'/></div>
            <p className='mt10 h cl2'>{props.data.name}</p>
        </Link>
    </div>
     

    )

}

export {AllCategories , SousCategories}